import { ShadowCard, AfterBottomRow } from "src/components";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { RequestDetailProps } from "src/shared/models/component/base.model";
import { useCallback } from "react";
import { useLazyGetDocQuery } from "src/store/services/privateApi";

export const RequestDetailBottom = ({ data, t }: RequestDetailProps) => {
  const [getDocument] = useLazyGetDocQuery();

  const handleDocumentClick = useCallback(
    async (doc: { Name: string; Uri: string; id: string }) => {
      try {
        const response = await getDocument(doc.id).unwrap();
        if (response?.uri) {
          window.open(response.uri, "_blank", "noopener,noreferrer");
        }
      } catch (error) {
        console.error("Failed to get document:", error);
        // You might want to add error handling UI here
      }
    },
    [getDocument]
  );

  return (
    <ShadowCard padding={4} marginBottom={8}>
      <Box display={"flex"} flexDirection={"column"}>
        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h3SemiBold" component={"p"} color={"blue.500"}>
              {t("formInput.areas")}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={"p"} color={"gray.700"}>
              {data?.Areas &&
                data?.Areas.length > 1 &&
                data?.Areas.map((item: string, index: any) => {
                  return `${item},`;
                })}
              {data?.Areas && data?.Areas.length === 1
                ? data?.Areas[0]
                : "----"}
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h3SemiBold" component={"p"} color={"blue.500"}>
              {t("formInput.category")}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={"p"} color={"gray.700"}>
              {data?.Category ? data?.Category : "----"}
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h3SemiBold" component={"p"} color={"blue.500"}>
              {"Issue Type"}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={"p"} color={"gray.700"}>
              {data?.issueType ? data?.issueType : "----"}
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h3SemiBold" component={"p"} color={"blue.500"}>
              {"Title"}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={"p"} color={"gray.700"}>
              {data?.title ? data?.title : "----"}
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content" flexDirection={"column"}>
          <Box className="content_left" marginBottom={2}>
            <Typography variant="h3SemiBold" component={"p"} color={"blue.500"}>
              {t("formInput.description")}
            </Typography>
          </Box>
          <Box className="content_right full">
            <Typography variant="h2" component={"p"} color={"gray.700"}>
              {data?.description ? data?.description : "----"}
            </Typography>
          </Box>
        </AfterBottomRow>

        {data?.Appointment === "Permission Given" && (
          <FormControlLabel
            sx={{ marginBottom: 1, marginTop: 2 }}
            control={
              <Checkbox
                name="confirm_subscription"
                size="small"
                checked={true}
              />
            }
            label={
              <Typography variant="h2" color={"gray.700"}>
                {t("formInput.consentCheck")}
              </Typography>
            }
          />
        )}

        <AfterBottomRow className="content">
          <Box className="content_left" marginBottom={2}>
            <Typography variant="h3SemiBold" component={"p"} color={"blue.500"}>
              {"Documents"}
            </Typography>
          </Box>
          <Box className="content_right full">
            {data?.Documents &&
            Array.isArray(data.Documents) &&
            data.Documents.length > 0 ? (
              data.Documents.map(
                (
                  doc: { Name: string; Uri: string; id: string },
                  index: number
                ) => (
                  <Box key={index} display="flex" alignItems="center" mb={1}>
                    <Typography
                      variant="h2"
                      component="p"
                      color="primary"
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => handleDocumentClick(doc)}
                    >
                      {doc.Name}
                    </Typography>
                  </Box>
                )
              )
            ) : (
              <Typography variant="h2" component={"p"} color={"gray.700"}>
                ----
              </Typography>
            )}
          </Box>
        </AfterBottomRow>
      </Box>
    </ShadowCard>
  );
};
