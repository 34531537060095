import { Box, Typography } from "@mui/material";

export const CredBuildBanner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 600,
        width: "100%",
        margin: "0 auto",
        padding: 2,
      }}
    >
      <Typography
        variant="h6"
        component="h2"
        sx={{
          fontWeight: 600,
          color: "text.primary",
          textAlign: "center",
        }}
      >
        Elevate Your Credit Score with Rent Reporting
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          px: 2,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            alignItems: "center",
            "&::before": {
              content: '"•"',
              marginRight: 1,
              color: "primary.main",
              fontSize: "1.2em",
            },
          }}
        >
          Transform your financial future effortlessly by reporting your rent
          payments. Witness a remarkable improvement in just 90 days!
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            alignItems: "center",
            "&::before": {
              content: '"•"',
              marginRight: 1,
              color: "primary.main",
              fontSize: "1.2em",
            },
          }}
        >
          Despite being a significant monthly expense, rent payments are often
          overlooked by credit bureaus. That's why we've introduced an easy
          solution to report these on-time payments.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            alignItems: "center",
            "&::before": {
              content: '"•"',
              marginRight: 1,
              color: "primary.main",
              fontSize: "1.2em",
            },
          }}
        >
          Price - $6.00/month added to your rent payment per subscribed
          renter.The subscription begins the first day of the following month
          and will be billed in conjunction with your Monthly Rent.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            alignItems: "center",
            "&::before": {
              content: '"•"',
              marginRight: 1,
              color: "primary.main",
              fontSize: "1.2em",
            },
          }}
        >
          <a
            href="https://www.credbuild.com/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "inherit",
              textDecoration: "underline",
              cursor: "pointer",
              marginRight: "16px",
            }}
          >
            Terms of Service
          </a>

          <a
            href="https://www.credbuild.com/residents"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "inherit",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            More Information
          </a>
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          borderRadius: 1,
          overflow: "hidden",
          boxShadow: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src="https://framerusercontent.com/images/XeekGjt2Te13WnPwml1JuCtuQQ.png?scale-down-to=1024"
          alt="Credit Building Illustration"
          sx={{
            maxWidth: 400,
            width: "100%",
            height: "auto",
            display: "block",
            objectFit: "cover",
          }}
        />
      </Box>
    </Box>
  );
};
